export const getSchool = /* GraphQL */ `
    query GetSchool($id: ID!) {
        getSchool(id: $id) {
            id
            beds
            slug
            name {
                legal
                popular
            }
            avatar {
                size
                name
                src
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            district {
                id
                slug
                name {
                    legal
                    popular
                }
                districtZoneId
            }
            staff {
                type
                name {
                    first
                    last
                }
                title
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            districtSchoolsId
            schoolZoneId
        }
    }
`;
export const getSchoolBySlug = /* GraphQL */ `
    query GetSchoolBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getSchoolBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                beds
                slug
                name {
                    legal
                    popular
                }
                avatar {
                    size
                    name
                    src
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                zone {
                    id
                    name
                    counties
                    createdAt
                    updatedAt
                }
                district {
                    id
                    slug
                    name {
                        legal
                        popular
                    }
                    districtZoneId
                }
                staff {
                    type
                    title
                    name {
                        first
                        last
                    }
                    phone {
                        type
                        number
                        ext
                    }
                    email {
                        type
                        address
                    }
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                districtSchoolsId
                schoolZoneId
            }
            nextToken
        }
    }
`;
export const updateSchool = /* GraphQL */ `
    mutation UpdateSchool(
        $input: UpdateSchoolInput!
        $condition: ModelSchoolConditionInput
    ) {
        updateSchool(input: $input, condition: $condition) {
            id
            beds
            slug
            name {
                legal
                popular
                __typename
            }
            avatar {
                size
                name
                src
                __typename
            }
            address {
                line1
                line2
                city
                county
                state
                zip
                __typename
            }
            zone {
                id
                name
                counties
                state {
                    enabled
                    __typename
                }
                createdAt
                updatedAt
                __typename
            }
            district {
                id
                beds
                slug
                name {
                    legal
                    popular
                    __typename
                }
                avatar {
                    size
                    name
                    src
                    __typename
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                    __typename
                }
                zone {
                    id
                    name
                    counties
                    createdAt
                    updatedAt
                    __typename
                }
                schools {
                    nextToken
                    __typename
                }
                staff {
                    type
                    title
                    __typename
                }
                users {
                    nextToken
                    __typename
                }
                state {
                    enabled
                    __typename
                }
                createdAt
                updatedAt
                districtZoneId
                __typename
            }
            staff {
                type
                name {
                    first
                    last
                    preferred
                    __typename
                }
                title
                phone {
                    type
                    number
                    ext
                    __typename
                }
                email {
                    type
                    address
                    __typename
                }
                __typename
            }
            users {
                items {
                    id
                    schoolID
                    userID
                    createdAt
                    updatedAt
                    __typename
                }
                nextToken
                __typename
            }
            state {
                enabled
                __typename
            }
            createdAt
            updatedAt
            districtSchoolsId
            schoolZoneId
            __typename
        }
    }
`;
export const listUserSchools = /* GraphQL */ `
    query ListUserSchools(
        $filter: ModelUserSchoolsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        size
                        name
                        src
                    }
                }
            }
            nextToken
        }
    }
`;
export const listStudentsBySchool = /* GraphQL */ `
    query ListStudentsBySchool(
        $schoolID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentsBySchool(
            schoolID: $schoolID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name {
                    first
                    last
                }
                grade
                schoolID
                createdAt
                updatedAt
                busStudentsId
                studentInvoiceId
            }
            nextToken
        }
    }
`;
export const listDistricts = /* GraphQL */ `
    query ListDistricts(
        $id: ID
        $filter: ModelDistrictFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDistricts(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
                districtZoneId
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;
export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
        }
    }
`;

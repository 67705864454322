<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Management" />
      <b-breadcrumb-item text="Schools" :to="{ name: 'management-schools' }" />
      <b-breadcrumb-item :text="school.name.legal" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-school">
        <b-button :disabled="!state.editing" variant="link" size="sm" @click="updateSchool">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" class="mr-50" /> Update
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-school">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateState">
          <font-awesome-icon :icon="['fas', school.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
          <span class="align-middle ml-50">{{ school.state.enabled ? 'Disable' : 'Enable' }}</span>
        </b-dropdown-item>
        <can do="delete" on="management-district">
          <b-dropdown-item @click="$refs.layout.confirmDelete(school, deleteSchool, cascadeConfirmDeleteOptions)">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider/>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <validation-observer v-if="school" ref="observer" tag="div">
        <b-row>
          <b-col cols="8">
            <b-card>
              <b-row>
                <b-col align-self="start" cols="auto">
                  <b-avatar v-if="hasCustomAvatar(school.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white" :disabled="!state.editing">
                    <b-img fluid fluid-grow :src="school.avatar.src" :alt="school.avatar.name"></b-img>
                    <template #badge>
                      <b-icon :icon="enabledIcon(school.state.enabled)" :variant="enabledIconVariant(school.state.enabled)"></b-icon>
                    </template>
                  </b-avatar>
                  <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                    <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                    <template #badge>
                      <b-icon :icon="enabledIcon(school.state.enabled)" :variant="enabledIconVariant(school.state.enabled)"></b-icon>
                    </template>
                  </b-avatar>
                  <avatar-modal v-if="state.editing"
                                title="School Avatar"
                                :avatar="school.avatar"
                                @update-avatar="updateAvatar"/>
                </b-col>
                <b-col align-self="center">
                  <b-row>
                    <b-col cols="6">
                      <validation-provider v-slot="validationContext" vid="legal-name" name="Legal Name" rules="required">
                        <b-form-group label="Legal Name" label-for="legal-name-input" :invalid-feedback="validationContext.errors[0]">
                          <b-input v-model="school.name.legal" :disabled="!state.editing"></b-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider v-slot="validationContext" vid="popular-name" name="Popular Name" rules="required">
                        <b-form-group label="Popular Name" label-for="popular-name-input" :invalid-feedback="validationContext.errors[0]">
                          <b-input v-model="school.name.popular" :disabled="!state.editing"></b-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <!-- District & Zone -->
            <b-card>
              <b-row>
                <b-col cols="auto">
                  <b-form-group label="Type" label-for="is-public">
                    <b-checkbox v-model="options.isPublic"
                                button button-variant="outline-primary" @input="onIsPublicInput">
                      {{ options.isPublic ? 'Public' : 'Non-Public' }}
                    </b-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="options.isPublic">
                  <validation-provider v-slot="validationContext" vid="district" name="District" rules="required">
                    <b-form-group label="District" label-for="district-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                      <v-select id="district-input"
                                v-model="school.districtSchoolsId"
                                label="name"
                                :options="districts"
                                :loading="options.districts.loading"
                                :reduce="option => option.id"
                                :selectable="option => option.state.enabled === true"
                                :filter="filterDistrict"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :disabled="!state.editing || !options.isPublic"
                                class="w-100"
                                @input="onDistrictInput">

                        <template #option="{ name, state }">
                          <div class="d-flex justify-content-between align-items-center">
                            <div>
                              <h6 class="mb-0">{{ name.legal }}</h6>
                              <small>{{ name.popular }}</small>
                            </div>
                            <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                          </div>
                        </template>

                        <template #selected-option="{ name }">
                          {{ name.legal }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider v-slot="validationContext" vid="zone" name="Zone" rules="required">
                    <b-form-group label="Zone" label-for="zone-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                      <v-select id="zone-input"
                                v-model="school.schoolZoneId"
                                label="name"
                                :options="zones"
                                :loading="options.zones.loading"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :disabled="!state.editing || school.districtSchoolsId !== null"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                placeholder="Zone"
                                class="w-100"
                                @input="school.address.county = null"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
            <!-- Address -->
            <b-card title="Address">
              <b-row>
                <b-col>
                  <validation-provider v-slot="validationContext" vid="line1" name="Line 1" :rules="rules.address.line1">
                    <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="line1-input" v-model="school.address.line1" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider v-slot="validationContext" vid="line2" name="Line 2" :rules="rules.address.line2">
                    <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="line2-input" v-model="school.address.line2" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <validation-provider v-slot="validationContext" vid="city" name="City" :rules="rules.address.city">
                    <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="city-input" v-model="school.address.city" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider v-slot="validationContext" vid="county" name="County" :rules="rules.address.county">
                    <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                      <v-select id="county-input"
                                v-model="school.address.county"
                                label="county"
                                :reduce="option => option.county"
                                :options="counties"
                                :disabled="!state.editing"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="auto">
                  <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.address.state">
                    <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                      <state-input id="state-input" v-model="school.address.state" :disabled="!state.editing" :validation-state="getValidationState(validationContext)"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="auto">
                  <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.address.zip">
                    <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                      <zip-input id="zip-code-input" v-model="school.address.zip" :disabled="!state.editing" :state="getValidationState(validationContext)"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="4">
            <!-- Staff -->
            <b-card no-body>
              <b-card-header class="d-flex justify-content-between pb-1">
                <b-card-title class="mb-0">Staff <small>({{ school.staff.length }})</small></b-card-title>
                <b-button v-if="state.editing" v-b-toggle.sidebar-staff-add size="sm" variant="link" class="p-0">
                  <feather-icon icon="PlusIcon" size="16" class="align-middle text-body"/>
                </b-button>
                <staff-sidebar @add="addStaff" @update="updateStaff"></staff-sidebar>
              </b-card-header>
              <b-list-group flush>
                <template v-if="school.staff.length">
                  <b-list-group-item v-for="(staff, index) in school.staff" :key="index" class="px-2">
                    <staff-item :staff.sync="staff" :editing="state.editing" @update="updateStaff(index, $event)" @remove="removeStaff(index)"></staff-item>
                  </b-list-group-item>
                </template>
                <template v-else>
                  <b-list-group-item class="px-2">
                        <span class="text-muted">
                           No staff associated to this school
                        </span>
                  </b-list-group-item>
                </template>
              </b-list-group>
            </b-card>
            <!-- Teachers -->
            <b-card no-body>
              <b-card-body>
                <b-card-title class="mb-0">Teachers <small>({{ users.items.length }})</small></b-card-title>
              </b-card-body>
              <b-overlay :show="users.loading" :opacity="1" variant="white">
                <b-list-group flush>
                  <template v-if="users.items.length">
                    <b-list-group-item v-for="user in users.items" :key="user.id" class="d-flex justify-content-between align-items-center">
                      <b-media no-body>
                        <b-media-aside class="mr-75">
                          <b-avatar v-if="hasCustomAvatar(user.avatar)" variant="light-primary" size="3.25em">
                            <b-img fluid fluid-grow :src="user.avatar.src" :alt="user.avatar.name"></b-img>
                          </b-avatar>
                          <b-avatar v-else variant="light-primary" size="3.25em">
                            <font-awesome-icon icon="fa-solid fa-chalkboard-user" size="lg"/>
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h6 class="mb-0">
                            {{ user.name.first }} {{ user.name.last }}
                          </h6>
                          <small>@{{ user.username }}</small>
                        </b-media-body>
                      </b-media>
                      <can do="read" on="management-user">
                        <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL" dropright>
                          <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                          </template>
                          <b-dropdown-item :to="{ name: 'management-user', params: { id: user.id, username: user.username } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">View</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </can>

                    </b-list-group-item>
                  </template>
                  <template v-else>
                    <b-list-group-item>
                        <span class="text-muted">
                           No teachers associated to this school
                        </span>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </b-overlay>
            </b-card>
            <!-- Students -->
            <b-card no-body>
              <b-card-body>
                <b-card-title class="mb-0">Students <small>({{ students.items.length }})</small></b-card-title>
              </b-card-body>
              <b-overlay :show="students.loading" :opacity="1" variant="white">
                <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-school-students">
                  <b-list-group flush>
                    <template v-if="students.items.length">
                      <b-list-group-item v-for="student in students.items" :key="student.id" class="d-flex justify-content-between align-items-center">
                        <b-media no-body>
                          <b-media-aside class="mr-75">
                            <b-avatar variant="light-primary" size="3.25em">
                              <font-awesome-icon icon="fa-solid fa-graduation-cap" size="lg"/>
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h6 v-if="student.name" class="mb-0">
                              {{ student.name.first }} {{ student.name.last }}
                            </h6>
                            <small>{{ student.grade }}th Grade</small>
                          </b-media-body>
                        </b-media>

                          <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL" dropright>
                            <template #button-content>
                              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                            </template>
                            <can do="read" on="all-state-student">
                              <b-dropdown-item :to="{ name: 'all-state-student', params: { id: student.id } }">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">View Student</span>
                              </b-dropdown-item>
                            </can>
                            <can v-if="student.studentInvoiceId" do="read" on="all-state-student">
                              <b-dropdown-item :to="{ name: 'management-invoice', params: { id: student.studentInvoiceId } }">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">View Invoice</span>
                              </b-dropdown-item>
                            </can>
                          </b-dropdown>


                      </b-list-group-item>
                    </template>
                    <template v-else>
                      <b-list-group-item>
                          <span class="text-muted">
                             No students associated to this school
                          </span>
                      </b-list-group-item>
                    </template>
                  </b-list-group>
                </vue-perfect-scrollbar>
              </b-overlay>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="6">
          <debug title="School" collapsed>{{ school }}</debug>
        </b-col>
        <b-col cols="6">
          <debug title="School Users" collapsed>{{ users.items }}</debug>
        </b-col>
        <b-col cols="6">
          <debug title="School Students" collapsed>{{ students.items }}</debug>
        </b-col>
        <b-col cols="6">
          <debug title="Zones" collapsed>{{ options.zones }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import StaffItem from '@/components/StaffItem.vue';
import StaffSidebar from '@/components/StaffSidebar.vue';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import avatarMixin from '@/mixins/avatar.mixin'
import print from '@/mixins/print.mixin'
import notify from '@/mixins/notify.mixin'
import vSelect from 'vue-select'
import slugify from 'slugify';
import Fuse from 'fuse.js';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { API, graphqlOperation } from 'aws-amplify';
import { getSchool, getSchoolBySlug, updateSchool, listUserSchools, listStudentsBySchool, listDistricts, listZones} from './queries/school';
import { cascadeDeleteSchool, cascadeConfirmDeleteOptions } from '@/graphql/cascade/school';

export default {
  name: 'School',
  components: {
    PageLayout,
    StaffSidebar,
    StaffItem,
    AvatarModal,
    ZipInput,
    StateInput,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [ avatarMixin, print, notify ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      school: null,
      users: {
        items: [],
        loading: true
      },
      students: {
        items: [],
        loading: true
      },
      options: {
        isPublic: true,
        districts: {
          items: [],
          loading: true,
          subscription: null
        },
        zones: {
          items: [],
          loading: true,
        },
        counties: {
          items: [],
          loading: true,
        }
      },
      rules: {
        address: {
          line1: { required: false },
          line2: { required: false },
          city: {
            required: false,
            alpha_spaces: true,
            max: 48
          },
          county: { required: false },
          state: {
            required: false,
            alpha: true,
            min: 2,
            max: 2
          },
          zip: {
            required: false,
            regex: /^\d{5}(?:[-\s]\d{4})?$/
          }
        },
        school: {
          beds: {
            required: true,
            numeric: true,
            min: 6,
            max: 12
          }
        }
      },
      icon: 'fas fa-school',
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    districts() {
      if(this.options.districts.loading) {
        this.listDistricts()
        return []
      }
      return this.options.districts.items
    },
    zones() {
      if(this.options.zones.loading) {
        this.listZones()
        return []
      }
      return this.options.zones.items
    },
    counties() {
      if(this.school.schoolZoneId) {
        return this.options.counties.items.filter(county => county.zone === this.school.schoolZoneId)
      }
      return this.options.counties.items
    },
    isPublic() {
      return this.school.district !== null
    }
  },
  async mounted() {
    await this.getSchool();
    await this.listSchoolUsers()
    await this.listSchoolStudents()
  },
  methods: {
    /** School **/
    async getSchool() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getSchool, { id: this.id }));
        this.school = response.data.getSchool
      }
      else {
        const response = await API.graphql(graphqlOperation(getSchoolBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.school = response.data.getSchoolBySlug.items[0]
      }
      if(this.school) {
        this.options.isPublic = this.school.district !== null
      }
      this.$refs.layout.state.loading = false
    },
    async patchSchool(input) {
      try {
        await API.graphql(graphqlOperation(updateSchool, { input: input } ));
        this.notify({ title: 'Success', text: 'School was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'School failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateSchool() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchSchool({
          id: this.school.id,
          name: this.school.name,
          slug: slugify(`${this.school.name.legal}-${this.school.beds}`, { lower: true }),
          address: this.school.address,
          staff: this.school.staff,
          schoolZoneId: this.school.schoolZoneId,
          districtSchoolsId: this.school.districtSchoolsId
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'School failed to update. Missing required fields.', icon: this.icon, variant: 'warning'});
      }
    },
    async deleteSchool(school, swalCallback) {
      await this.cascadeDeleteSchool(school.id, swalCallback)
      await this.notify({ title: 'Success', text: 'School was successfully deleted', icon: this.icon, variant: 'success' });
      await this.$router.push({ name: 'management-schools' })
    },
    cascadeDeleteSchool,
    
    async updateAvatar(avatar) {
      this.school.avatar = avatar;
      await this.patchSchool({ id: this.school.id, avatar: this.school.avatar })
    },
    async updateState() {
      this.school.state.enabled = !this.school.state.enabled;
      await this.patchSchool({ id: this.school.id, state: this.school.state })
    },

    /** School Users **/
    async listSchoolUsers(nextToken, pagedItems) {
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listUserSchools, { limit: 250, nextToken: nextToken, filter: { schoolID: { eq: this.school.id } } }));
      items.push(...response.data.listUserSchools.items);

      if(response.data.listUserSchools.nextToken) {
        await this.listSchoolUsers(response.data.listUserSchools.nextToken, items)
      }
      else {
        this.users.items = items.map(item => item.user).sort((a, b) => a.name?.first.localeCompare(b.name?.first) || a.name?.last.localeCompare(b.name?.last))
        this.users.loading = false
      }
    },

    /** School Students **/
    async listSchoolStudents(nextToken, pagedItems) {
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listStudentsBySchool, { schoolID: this.school.id, limit: 250, nextToken: nextToken }));
      items.push(...response.data.listStudentsBySchool.items);

      if(response.data.listStudentsBySchool.nextToken) {
        await this.listSchoolStudents(response.data.listStudentsBySchool.nextToken, items)
      }
      else {
        this.students.items = items.sort((a, b) => a.grade - b.grade || a.name.first.localeCompare(b.name.first) || a.name.last.localeCompare(b.name.last))
        this.students.loading = false
      }
    },

    /** Options Data **/
    async listZones(nextToken, pagedItems) {
      this.options.counties.loading = true

      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listZones, { limit: 500, nextToken: nextToken }));

      items.push(...response.data.listZones.items)

      if(response.data.listZones.nextToken) {
        await this.listZones(response.data.listZones.nextToken, items)
      }
      else {
        this.options.zones.items = items.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        this.options.zones.loading = false

        const counties = []
        items.forEach(item => {
          counties.push(...item.counties.map(county => ({ county, zone: item.id})))
        })

        this.options.counties.items = counties.sort((a, b) => a.county.localeCompare(b.county));
        this.options.counties.loaded = true
        this.options.counties.loading = false
      }
    },
    async listDistricts(nextToken, pagedDistricts) {
      this.options.districts.loading = true

      const districts = pagedDistricts || []
      const response = await API.graphql(graphqlOperation(listDistricts, { limit: 500, nextToken: nextToken }));

      districts.push(...response.data.listDistricts.items)

      if(response.data.listDistricts.nextToken) {
        await this.listDistricts(response.data.listDistricts.nextToken, districts)
      }
      else {
        this.options.districts.items = districts.sort((a, b) => a.name.legal.localeCompare(b.name.legal));
        this.options.districts.loading = false
      }
    },

    /** Actions **/
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.getSchool()
      await this.listSchoolUsers()
      await this.listSchoolStudents()
      this.$refs.layout.state.loading = false
    },

    /** Staff **/
    addStaff(staff) {
      this.school.staff.push(staff)
      this.patchSchool({ id: this.school.id, staff: this.school.staff })
    },
    updateStaff(index, staff) {
      this.school.staff.splice(index, 1, staff)
      this.patchSchool({ id: this.school.id, staff: this.school.staff })
    },
    removeStaff(index) {
      this.school.staff.splice(index, 1)
      this.patchSchool({ id: this.school.id, staff: this.school.staff })
    },

    /** Input **/
    onDistrictInput(value) {
      if(value) {
        this.school.schoolZoneId = this.options.districts.items.find(district => district.id === value).districtZoneId
      }
      else {
        this.school.schoolZoneId = null
      }
    },
    onIsPublicInput(value) {
      if(value === false) { this.school.districtSchoolsId = null }
    },
    filterDistrict(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        shouldSort: true,
        threshold: 0.2
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';

  .ps-school-students {
    max-height: 30vh;
  }

  .per-page-selector {
    min-width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<template>
  <b-sidebar id="sidebar-staff-add" ref="sidebar-staff-add" lazy sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-close-on-backdrop no-header right @hidden="reset">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add Staff</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>

      <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
        <b-form ref="staff-add" class="p-2" @submit.stop.prevent="handleSubmit(add)">

          <validation-provider #default="{ errors }" name="type" rules="required">
            <b-form-group label="Type" label-for="type" :state="errors.length > 0 ? false:null">
              <v-select
                  id="tone"
                  v-model="staff.type"
                  :options="['Principle', 'Music Director']"
                  :select-on-tab="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="First Name" label-for="first-name">
            <validation-provider #default="{ errors }" vid="first-name" name="First Name" rules="required">
              <b-form-input id="first-name" v-model="staff.name.first" trim placeholder="John"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Last Name" label-for="last-name">
            <validation-provider #default="{ errors }" vid="last-name" name="Last Name" rules="required">
              <b-form-input id="last-name" v-model="staff.name.last" trim placeholder="Doe"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Title" label-for="title">
            <validation-provider #default="{ errors }" vid="title" name="Title" rules="">
              <b-form-input id="title"
                            v-model="staff.title" trim
                            :state="errors.length > 0 ? false:null"
                            placeholder="Principal"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Phone Number" label-for="phone-number">
            <validation-provider #default="{ errors }" vid="phone-number" name="Phone Number" rules="">
              <b-form-input id="phone-number"
                            v-model="staff.phone.number"
                            v-mask="'(###) ###-####'"
                            :state="errors.length > 0 ? false:null"
                            placeholder="(555) 555-5555"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email Address" label-for="email-address">
            <validation-provider #default="{ errors }" vid="email-address" name="Email" rules="email">
              <b-form-input id="email-address" v-model="staff.email.address" trim placeholder="John Doe"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row class="mt-2">
            <b-col class="pr-50">
              <b-button block variant="primary" @click="$refs.observer.handleSubmit(add)">Add</b-button>
            </b-col>
            <b-col class="pl-50">
              <b-button block variant="secondary" @click="hide">Close</b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {VueMaskDirective} from 'v-mask';
import vSelect from 'vue-select'

export default {
  name: 'StaffSidebar',
  directives: { mask: VueMaskDirective },
  components: {
    vSelect
  },
  props: {

  },
  data() {
    return {
      staff: {
        type: null,
        name: {
          first: null,
          last: null
        },
        title: null,
        email: {
          type: null,
          address: null
        },
        phone: {
          type: null,
          number: null,
          ext: null
        }
      }
    }
  },
  methods: {
    reset() {
      this.staff = {
        type: null,
        name: {
          first: null,
          last: null
        },
        title: null,
        email: {
          type: null,
          address: null
        },
        phone: {
          type: null,
          number: null
        }
      }
    },
    add() {
      this.$refs['sidebar-staff-add'].hide()
      this.$emit('add', this.staff)
    }
  }
}
</script>

<style scoped>

</style>

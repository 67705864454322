<template>
  <div>
    <!-- Default View -->
    <div v-if="staff" class="d-flex justify-content-between align-items-center" @click="state.collapse = !state.collapse">
      <b-media no-body class="w-100">
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="42" :src="staff.avatar"/>
        </b-media-aside>
        <b-media-body class="align-self-start">
          <h6 v-if="staff.name" class="mb-0">
            {{ staff.name.first }} {{ staff.name.last }}
          </h6>
          <h6 v-else class="mb-0 text-danger italic">
            Missing Name
          </h6>
          <small>{{ staff.title }}</small>
          <!-- Expanded View -->
          <b-collapse v-model="state.collapse">
            <div class="mt-1 d-flex align-items-center">
              <span v-if="staff.phone" class="pr-50">
                <small>Phone: {{ staff.phone.number ? staff.phone.number : 'N/A' }} {{ staff.phone.number && staff.phone.ext ? staff.phone.ext : null }}</small>
              </span> |
              <span v-if="staff.email" class="pl-50">
                <small>Email: {{ staff.email.address ? staff.email.address : 'N/A' }}</small>
              </span>
            </div>
          </b-collapse>
        </b-media-body>
      </b-media>
      <b-dropdown v-if="editing" variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL" dropright>
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
        </template>
        <b-dropdown-item @click="state.sidebar = !state.sidebar">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item>
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50" @click="remove">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>



    <!-- Edit Side Bar -->
    <b-sidebar id="sidebar-staff-update" ref="sidebar-staff-update" v-model="state.sidebar" lazy sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Edit Staff</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>

        <b-form class="p-2">
          <b-form-group label="First Name" label-for="first-name">
            <b-form-input id="first-name" v-model="staffInput.name.first" trim placeholder="John Doe"/>
          </b-form-group>

          <b-form-group label="Last Name" label-for="last-name">
            <b-form-input id="last-name" v-model="staffInput.name.last" trim placeholder="John Doe"/>
          </b-form-group>

          <b-form-group label="Title" label-for="title">
            <b-form-input id="title" v-model="staffInput.title" trim placeholder="John Doe"/>
          </b-form-group>

          <b-form-group label="Phone Number" label-for="phone-number">
            <b-form-input id="phone-number" v-model="staffInput.phone.number" trim placeholder="John Doe"/>
          </b-form-group>

          <b-form-group label="Email Address" label-for="email-address">
            <b-form-input id="email-address" v-model="staffInput.email.address" trim placeholder="John Doe"/>
          </b-form-group>

          <b-row class="mt-2">
            <b-col class="pr-50">
              <b-button block variant="primary" @click="update">Update</b-button>
            </b-col>
            <b-col class="pl-50">
              <b-button block variant="secondary" @click="hide">Close</b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {VueMaskDirective} from 'v-mask';

export default {
  name: 'StaffItem',
  directives: { mask: VueMaskDirective },
  props: {
    staff: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      staffInput: {
        name: {
          first: this.staff.name ? this.staff.name.first : null,
          last: this.staff.name ? this.staff.name.last : null
        },
        title: this.staff.title,
        phone: {
          number: this.staff.phone ? this.staff.phone.number : null,
          ext: this.staff.phone ? this.staff.phone.ext : null,
        },
        email: {
          address: this.staff.email ? this.staff.email.address : null,
        }
      },
      state: {
        sidebar: false,
        collapse: false
      }
    }
  },
  watch: {
    staff: {
      handler() {
        this.staffInput = {
          name: {
            first: this.staff.name ? this.staff.name.first : null,
            last: this.staff.name ? this.staff.name.last : null
          },
          title: this.staff.title,
          phone: {
            number: this.staff.phone ? this.staff.phone.number : null,
            ext: this.staff.phone ? this.staff.phone.ext : null,
          },
          email: {
            address: this.staff.email ? this.staff.email.address : null,
          }
        }
      },
      deep: true
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    },
    update() {
      this.$emit('update', this.staffInput)
      this.$refs['sidebar-staff-update'].hide()
    },
  }
}
</script>

<style scoped>

</style>

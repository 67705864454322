var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar-staff-add",attrs:{"id":"sidebar-staff-add","lazy":"","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-close-on-backdrop":"","no-header":"","right":""},on:{"hidden":_vm.reset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Add Staff")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"staff-add",staticClass:"p-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.add)}}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type","label-for":"type","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"tone","options":['Principle', 'Music Director'],"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.staff.type),callback:function ($$v) {_vm.$set(_vm.staff, "type", $$v)},expression:"staff.type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"First Name","label-for":"first-name"}},[_c('validation-provider',{attrs:{"vid":"first-name","name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","trim":"","placeholder":"John"},model:{value:(_vm.staff.name.first),callback:function ($$v) {_vm.$set(_vm.staff.name, "first", $$v)},expression:"staff.name.first"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last-name"}},[_c('validation-provider',{attrs:{"vid":"last-name","name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","trim":"","placeholder":"Doe"},model:{value:(_vm.staff.name.last),callback:function ($$v) {_vm.$set(_vm.staff.name, "last", $$v)},expression:"staff.name.last"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"vid":"title","name":"Title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","trim":"","state":errors.length > 0 ? false:null,"placeholder":"Principal"},model:{value:(_vm.staff.title),callback:function ($$v) {_vm.$set(_vm.staff, "title", $$v)},expression:"staff.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone-number"}},[_c('validation-provider',{attrs:{"vid":"phone-number","name":"Phone Number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"id":"phone-number","state":errors.length > 0 ? false:null,"placeholder":"(555) 555-5555"},model:{value:(_vm.staff.phone.number),callback:function ($$v) {_vm.$set(_vm.staff.phone, "number", $$v)},expression:"staff.phone.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email Address","label-for":"email-address"}},[_c('validation-provider',{attrs:{"vid":"email-address","name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email-address","trim":"","placeholder":"John Doe"},model:{value:(_vm.staff.email.address),callback:function ($$v) {_vm.$set(_vm.staff.email, "address", $$v)},expression:"staff.email.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"pr-50"},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.$refs.observer.handleSubmit(_vm.add)}}},[_vm._v("Add")])],1),_c('b-col',{staticClass:"pl-50"},[_c('b-button',{attrs:{"block":"","variant":"secondary"},on:{"click":hide}},[_vm._v("Close")])],1)],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }